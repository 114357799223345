<template>
  <iq-card class="ml-2 mr-2">
    <template v-slot:body>
      <div class="col-sm-12 text-center">
        <div
          class="text-white"
          :style="
            'background: url(' +
            bgImageURL +
            ') no-repeat 0 0; background-size: cover; padding: 70px'
          "
        >
          <img
            style="margin-top: 80px"
            :src="require('@/assets/images/logo/logo-negativo.png')"
            class="img-fluid mb-4"
            alt="logo"
          />
          <h4 class="mb-1 text-white">Plataforma virtual</h4>
          <p>Para Colegios y Jardines.</p>
        </div>
      </div>
    </template>
  </iq-card>
</template>
<script>
import Loader from "@/components/core/loader/Loader";
import logo from "@/assets/images/logo-white.png";
import { core } from "@/config/pluginInit";
import bgImage from "@/assets/images/login/login-2.jpg";
import Swiper from "@/components/core/slider/Swiper";
import SwiperSlide from "@/components/core/slider/SwiperSlide";

export default {
  name: "AuthLayout",
  components: {
    Loader,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      options: {
        slidesPerView: 1,
        loop: true,
      },
      logo: logo,
      bgImageURL: bgImage,
    };
  },
};
</script>
<style>
@import url("../../assets/css/custom.css");
</style>